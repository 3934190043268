<template>
  <page-header-wrapper :content="'用户ID：' + this.queryParam.playerId">
    <a-card :bordered="false">
      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data="loadData"
      >
      </s-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable } from '@/components'
import { getPlayerCharacterList } from '@/api/game/mai2'

export default {
  name: 'PlayerCharacterList',
  components: {
    STable
  },
  data () {
    return {
      roleFormVisible: false,
      confirmLoading: false,
      formModel: null,
      queryParam: {
        playerId: this.$route.params.playerId
      },
      loadData: parameter => {
        return getPlayerCharacterList(Object.assign(parameter, this.queryParam))
      },
      columns: [
        {
          title: '角色ID',
          dataIndex: 'characterId',
          sorter: true
        },
        {
          title: '等级',
          dataIndex: 'level',
          sorter: true
        },
        {
          title: '觉醒数',
          dataIndex: 'awakening',
          sorter: true
        },
        {
          title: '使用次数',
          dataIndex: 'useCount',
          sorter: true
        }
      ]
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
